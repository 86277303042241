import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import b64ToBlob from 'b64-to-blob';
import fileSaver from 'file-saver';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { APIStatus } from '../../../enum';
import type { NameValue } from '../../../models/dashboard';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import type { AdminAdvocate } from '../admin-advocates/adminAdvocateSlice';
import { months } from './report.helper';

const { logError } = RaygunErrorHandlerService();

type TimesheetRequestBody = {
  advocate_id: string | null;
  month: number;
  year: number;
};

type TimesheetsSliceType = {
  timesheetChatCVS: string;
  timesheetPaymentCSV: string;
  timesheetStudentCSV: string;
  timesheetChatApiCSVStatus: APIStatus;
  timesheetPaymentApiSatus: APIStatus;
  timesheetInvoiceApiStatus: APIStatus;
  timesheetStudentCSVApiStatus: APIStatus;
  selectedAdvocate: NameValue | null;
  timesheetRequestBody: TimesheetRequestBody;
  timesheetAdvocates: AdminAdvocate[];
};

const initialState: TimesheetsSliceType = {
  timesheetChatCVS: '',
  timesheetPaymentCSV: '',
  timesheetStudentCSV: '',
  timesheetChatApiCSVStatus: APIStatus.IDLE,
  timesheetPaymentApiSatus: APIStatus.IDLE,
  timesheetInvoiceApiStatus: APIStatus.IDLE,
  timesheetStudentCSVApiStatus: APIStatus.IDLE,
  selectedAdvocate: null,
  timesheetRequestBody: {
    advocate_id: null,
    month: new Date().getMonth() || 12,
    year: new Date().getMonth() ? new Date().getFullYear() : new Date().getFullYear() - 1,
  },
  timesheetAdvocates: [],
};

export const getTimesheetCSV = createAsyncThunk<string, undefined, AsyncThunkConfig>(
  'timesheets/getTimesheetCSV',
  async (_, thunkAPI) => {
    try {
      const { timesheetsSlice } = thunkAPI.getState();
      const response = await axios.post('v0_get_timesheet', timesheetsSlice.timesheetRequestBody);
      const csvData = response as unknown as string;
      if (csvData.length === 0) {
        notification.error({
          message: 'Error',
          description: `No data for ${timesheetsSlice.timesheetRequestBody.year}/${timesheetsSlice.timesheetRequestBody.month}`,
        });
      }
      return csvData;
    } catch (e) {
      logError(e, ['timesheetsSlice', 'getTimesheetCSV']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getPaymentAmountsCSV = createAsyncThunk<string, undefined, AsyncThunkConfig>(
  'timesheets/getPaymentAmountsCSV',
  async (_, thunkAPI) => {
    try {
      const { timesheetsSlice } = thunkAPI.getState();
      const response = await axios.post('v0_get_payment_amounts', timesheetsSlice.timesheetRequestBody);
      const csvData = response as unknown as string;
      if (csvData.length === 0) {
        notification.error({
          message: 'Error',
          description: `No data for ${timesheetsSlice.timesheetRequestBody.year}/${timesheetsSlice.timesheetRequestBody.month}`,
        });
      }
      return csvData;
    } catch (e) {
      logError(e, ['timesheetsSlice', 'getPaymentAmountsCSV']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getStudentHoursCSV = createAsyncThunk<string, undefined, AsyncThunkConfig>(
  'timesheets/getStudentHoursCSV',
  async (_, thunkAPI) => {
    try {
      const { timesheetsSlice } = thunkAPI.getState();
      const response = await axios.post('v0_get_student_online_hours', timesheetsSlice.timesheetRequestBody);
      const csvData = response as unknown as string;
      if (csvData.length === 0) {
        notification.error({
          message: 'Error',
          description: `No data for ${timesheetsSlice.timesheetRequestBody.year}/${timesheetsSlice.timesheetRequestBody.month}`,
        });
      }
      return csvData;
    } catch (e) {
      logError(e, ['timesheetsSlice', 'getStudentHoursCSV']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getInvoicePDF = createAsyncThunk<string, undefined, AsyncThunkConfig>(
  'timesheets/getInvoicePDF',
  async (_, thunkAPI) => {
    try {
      const { timesheetRequestBody, timesheetAdvocates } = thunkAPI.getState().timesheetsSlice;
      const zipAsBase64 = (await axios.post('v0_get_invoice', timesheetRequestBody)) as string;
      const { month, year, advocate_id } = timesheetRequestBody;
      const blob = b64ToBlob(zipAsBase64, 'application/zip');
      const filter = advocate_id ? timesheetAdvocates.find((a) => a.id === advocate_id)?.full_name : 'All advocates';
      const period = `${months[month - 1].label} ${year}`;
      const zipName = `Invoice${advocate_id ? '' : 's'} ${period} (${filter}).zip`;
      fileSaver.saveAs(blob, zipName);
      return 'file';
    } catch (e) {
      logError(e, ['timesheetsSlice', 'getInvoicePDF']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchTimesheetAdvocates = createAsyncThunk<AdminAdvocate[], undefined, AsyncThunkConfig>(
  'timesheets/fetchAdminAdvocates',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post('v0_get_leps', { filteredInfo: { advocate_type: ['Paid Advocate'] } });
      return response as unknown as AdminAdvocate[];
    } catch (e) {
      logError(e, ['timesheetsSlice', 'fetchTimesheetAdvocates']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const timesheetsSlice = createSlice({
  name: 'timesheets',
  initialState,
  reducers: {
    handleTimesheetRequestBodyChange: (state, action: PayloadAction<Partial<TimesheetRequestBody>>) => {
      state.timesheetRequestBody = { ...state.timesheetRequestBody, ...action.payload };
    },
    clearFilters: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimesheetCSV.pending, (state, _action) => {
        state.timesheetChatApiCSVStatus = APIStatus.PENDING;
      })
      .addCase(getTimesheetCSV.fulfilled, (state, action) => {
        state.timesheetChatCVS = action.payload;
        state.timesheetChatApiCSVStatus = APIStatus.FULFILLED;
      })
      .addCase(getTimesheetCSV.rejected, (state, _action) => {
        state.timesheetChatApiCSVStatus = APIStatus.ERROR;
      })
      .addCase(getPaymentAmountsCSV.pending, (state, _action) => {
        state.timesheetPaymentApiSatus = APIStatus.PENDING;
      })
      .addCase(getPaymentAmountsCSV.fulfilled, (state, action) => {
        state.timesheetPaymentCSV = action.payload;
        state.timesheetPaymentApiSatus = APIStatus.FULFILLED;
      })
      .addCase(getPaymentAmountsCSV.rejected, (state, _action) => {
        state.timesheetPaymentApiSatus = APIStatus.ERROR;
      })
      .addCase(getStudentHoursCSV.pending, (state, _action) => {
        state.timesheetStudentCSVApiStatus = APIStatus.PENDING;
      })
      .addCase(getStudentHoursCSV.fulfilled, (state, action) => {
        state.timesheetStudentCSV = action.payload;
        state.timesheetStudentCSVApiStatus = APIStatus.FULFILLED;
      })
      .addCase(getStudentHoursCSV.rejected, (state, _action) => {
        state.timesheetStudentCSVApiStatus = APIStatus.ERROR;
      })
      .addCase(getInvoicePDF.pending, (state, _action) => {
        state.timesheetInvoiceApiStatus = APIStatus.PENDING;
      })
      .addCase(getInvoicePDF.fulfilled, (state, _action) => {
        state.timesheetInvoiceApiStatus = APIStatus.FULFILLED;
      })
      .addCase(getInvoicePDF.rejected, (state, _action) => {
        state.timesheetInvoiceApiStatus = APIStatus.ERROR;
      })
      .addCase(fetchTimesheetAdvocates.fulfilled, (state, action) => {
        state.timesheetAdvocates = action.payload;
      });
  },
});

export const { handleTimesheetRequestBodyChange, clearFilters } = timesheetsSlice.actions;
