import { notification } from 'antd';

import { AdvocatePage, Org, Role } from '../enum';
import { environment } from '../environment/environment';

export const isUndefinedOrNull = <T>(value: T) => {
  return value === undefined || value === null;
};

export const validateAsAdmin = (role: Role) => [Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN].includes(role);
export const validateAsHighRole = (role: Role) => [Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.DIRECTOR].includes(role);
export const validateAsRegionalRole = (role: Role) => [Role.DIRECTOR, Role.MANAGER].includes(role);
export const validateAsFirstAlertsRole = (role: Role) => [Role.ALERT_MANAGER, Role.ALERT_DISPATCHER].includes(role);
export const validateAsServiceProviderManagerRole = (role: Role) => role === Role.SERVICE_PROVIDER_MANAGER;

export const validateAsSKOrg = (orgCode: Org) => orgCode === Org.Saskatchewan;

export const setPageTitle = (title: string) => {
  document.title = title;
  if (environment.environment_deploy === 'local') {
    document.title += ' (' + environment.environment_deploy + ')';
  }
};

export const getAdvocateLabel = ({
  role,
  plural = false,
  sentenceCase = false,
  advocatePageType = AdvocatePage.PeerAdvocate,
}: {
  role: Role;
  plural?: boolean;
  sentenceCase?: boolean;
  advocatePageType?: AdvocatePage;
}) => {
  const label = plural
    ? [Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.SERVICE_PROVIDER_MANAGER].includes(role) &&
      advocatePageType === AdvocatePage.ServiceProvider
      ? 'service providers'
      : 'advocates'
    : [Role.SUPER_ADMIN, Role.EXTERNAL_ADMIN, Role.SERVICE_PROVIDER_MANAGER].includes(role) &&
        advocatePageType === AdvocatePage.ServiceProvider
      ? 'service provider'
      : 'advocate';

  return sentenceCase ? label.charAt(0).toUpperCase() + label.slice(1) : label;
};

export const getAdvocatePageType = (pathname: string) =>
  pathname.includes('advocate')
    ? AdvocatePage.PeerAdvocate
    : pathname.includes('service-provider')
      ? AdvocatePage.ServiceProvider
      : AdvocatePage.PeerAdvocate;

export const showValidationError = (title: string, message: string) => {
  if (!title) title = 'Error';
  notification.error({
    message: title,
    description: message,
  });
};

export const notifyMissingFields = (missingRequiredFields: string[]) => {
  const lastFieldName = missingRequiredFields.pop();
  let fieldNames = lastFieldName;
  if (missingRequiredFields.length > 0) {
    fieldNames =
      missingRequiredFields.join(', ') + `${missingRequiredFields.length > 1 ? ',' : ''} and ${lastFieldName}`;
  }
  showValidationError('Missing required fields', `Missing a value for ${fieldNames}.`);
  return false;
};

export const isBurns = environment.isBurns ?? window.location.origin.includes('theburnsway');

export const appName = isBurns ? 'the Burns Way' : 'Talking Stick';
