import { useState } from 'react';
import { Layout } from 'antd';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import { useCheckOrientation } from '../../hooks/useCheckOrientation';
import { isMobile } from '../../store';
import FooterComponent from './footer';
import HeaderComponent from './header';
import SideNav from './sidebar';

const { Sider, Content, Header } = Layout;

const MainLayout = () => {
  useCheckOrientation();
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 760);

  return (
    <Layout>
      <ScrollRestoration />
      <Sider
        width={285}
        className="sideBar"
        style={{ overflow: 'auto', height: '100vh', position: 'sticky', top: 0, left: 0, bottom: 0 }}
        trigger={null}
        collapsed={collapsed}
        theme="light"
        collapsible
      >
        <SideNav collapsed={collapsed} setCollapsed={setCollapsed} />
      </Sider>

      {isMobile && (
        <Header
          className="headerComponent"
          style={{ padding: 0, background: 'white', position: 'fixed', zIndex: 1, width: '100%' }}
        >
          <HeaderComponent />
        </Header>
      )}

      <Content
        className="appContainer flexColumnSpaceBetween"
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          paddingBottom: 12,
          minHeight: '100vh',
          marginTop: 20,
          overflow: 'auto',
        }}
      >
        <Outlet />
        <FooterComponent />
      </Content>
    </Layout>
  );
};

export default MainLayout;
