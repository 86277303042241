import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { Org } from '../../../enum';
import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { type FilteredInfo, type RequestParams, SortedInfo, type TableChange } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { removeEmpty } from '../../../service/table.service';
import { validateAdminSPOrganization } from './admin-service-provider-organizations.helper';

const { logError } = RaygunErrorHandlerService();

export class AdminSPOrganization {
  id: string | undefined = undefined;
  name: string | undefined = undefined;
  internal: boolean | undefined = undefined;
  org_code: Org | undefined = undefined;
  org_name: string | undefined = undefined;
  total_count?: number = undefined;
}

export enum ADMIN_SP_ORGANIZATION_MODAL {
  IDLE = 'idle',
  EDIT = 'edit',
}

type AdminSPOrganizationsSlideType = {
  adminSPOrganizations: AdminSPOrganization[];
  requestAdminSPOrganizationsParams: RequestParams<AdminSPOrganization>;
  adminSPOrganizationsApiStatus: APIStatus;
  adminSPOrganizationsModal: ADMIN_SP_ORGANIZATION_MODAL;
  adminSPOrganization: AdminSPOrganization;
};

const initialState: AdminSPOrganizationsSlideType = {
  adminSPOrganizations: [],
  requestAdminSPOrganizationsParams: {
    pagination: {
      current: 1,
      pageSize: 50,
      defaultPageSize: 50,
    },
    sortedInfo: new SortedInfo<AdminSPOrganization>('name', 'ascend'),
    filteredInfo: {},
  },
  adminSPOrganizationsApiStatus: APIStatus.IDLE,
  adminSPOrganizationsModal: ADMIN_SP_ORGANIZATION_MODAL.IDLE,
  adminSPOrganization: new AdminSPOrganization(),
};

export const fetchAdminSPOrganizations = createAsyncThunk<AdminSPOrganization[], undefined, AsyncThunkConfig>(
  'adminSPOrganizations/fetchAdminSPOrganizations',
  async (_, thunkAPI) => {
    try {
      const params = thunkAPI.getState().adminSPOrganizationsSlice.requestAdminSPOrganizationsParams;
      const response = (await axios.post('v0_get_service_provider_organizations', params)) as AdminSPOrganization[];
      return response ?? [];
    } catch (e) {
      logError(e, ['adminSPOrganizationsSlice', 'fetchAdminSPOrganizations']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

type AdminSPOrganizationUpsertResponse = {
  id: string;
  is_update: boolean;
};

export const upsertAdminSPOrganization = createAsyncThunk<
  AdminSPOrganizationUpsertResponse,
  AdminSPOrganization,
  AsyncThunkConfig
>('adminSPOrganizations/upsertAdminSPOrganization', async (upsertAdminSPOrganization, thunkAPI) => {
  try {
    if (validateAdminSPOrganization(upsertAdminSPOrganization)) {
      const response = (await axios.post(
        'v0_upsert_service_provider_organization',
        upsertAdminSPOrganization,
      )) as AdminSPOrganizationUpsertResponse;
      if (response) {
        thunkAPI.dispatch(fetchAdminSPOrganizations());
        thunkAPI.dispatch(closeAdminSPOrganizationModal());
        notification.success({
          message: `Service provider organization ${response.is_update ? 'updated' : 'added'} successfully`,
          description: 'Success',
        });
      }
      return response;
    }
  } catch (e) {
    logError(e, ['adminSPOrganizationsSlice', 'upsertAdminSPOrganization']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const adminSPOrganizationsSlice = createSlice({
  name: 'adminSPOrganizations',
  initialState,
  reducers: {
    handleAdminSPOrganizationsTableChange: (state, action: PayloadAction<TableChange<AdminSPOrganization>>) => {
      const filters = action.payload.filters as FilteredInfo;
      const sorter = action.payload.sorter as SortedInfo<AdminSPOrganization>;
      if (!sorter.order) {
        sorter.order = null;
      }
      state.requestAdminSPOrganizationsParams.pagination = action.payload.pagination;
      state.requestAdminSPOrganizationsParams.sortedInfo = sorter;
      state.requestAdminSPOrganizationsParams.filteredInfo = removeEmpty(filters);
    },
    clearAdminSPOrganizationsFilter: (state) => {
      state.requestAdminSPOrganizationsParams.pagination = initialState.requestAdminSPOrganizationsParams.pagination;
      state.requestAdminSPOrganizationsParams.sortedInfo = initialState.requestAdminSPOrganizationsParams.sortedInfo;
      state.requestAdminSPOrganizationsParams.filteredInfo =
        initialState.requestAdminSPOrganizationsParams.filteredInfo;
    },
    showEditModal: (state, action: PayloadAction<ADMIN_SP_ORGANIZATION_MODAL>) => {
      state.adminSPOrganizationsModal = action.payload;
    },
    setAdminSPOrganization: (state, action: PayloadAction<AdminSPOrganization>) => {
      state.adminSPOrganization = action.payload;
    },
    closeAdminSPOrganizationModal: (state) => {
      state.adminSPOrganizationsModal = ADMIN_SP_ORGANIZATION_MODAL.IDLE;
      state.adminSPOrganization = new AdminSPOrganization();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminSPOrganizations.pending, (state, _action) => {
        state.adminSPOrganizationsApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAdminSPOrganizations.fulfilled, (state, action) => {
        state.adminSPOrganizations = action.payload;
        state.adminSPOrganizationsApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAdminSPOrganizations.rejected, (state, _action) => {
        state.adminSPOrganizationsApiStatus = APIStatus.ERROR;
      });
  },
});

export const {
  handleAdminSPOrganizationsTableChange,
  clearAdminSPOrganizationsFilter,
  showEditModal,
  setAdminSPOrganization,
  closeAdminSPOrganizationModal,
} = adminSPOrganizationsSlice.actions;
