import { notifyMissingFields } from '../../../helpers/utils.helper';
import type { AdminSPOrganization } from './adminSPOrganizationsSlice';

export const validateAdminSPOrganization = (adminSPOrganization: AdminSPOrganization) => {
  const missingRequiredFields = [];
  if (!adminSPOrganization.name) missingRequiredFields.push('name');
  if (!adminSPOrganization.org_code) missingRequiredFields.push('organization');
  if (adminSPOrganization.internal === undefined) missingRequiredFields.push('internal');

  if (missingRequiredFields.length > 0) {
    return notifyMissingFields(missingRequiredFields);
  } else {
    return true;
  }
};
