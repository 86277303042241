import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { JwtPayload } from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { Org, Role } from '../../../enum';
import { APIStatus } from '../../../enum';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import { getRememberedEmail, saveRememberedEmail } from './login.helper';

const { logError, resetRaygunUser, setRaygunUser } = RaygunErrorHandlerService();

export class AuthResult {
  first_name: string | null = null;
  token: string | null = null;
  /** Admins don't have it. */
  org_name: string | null = null;
  /** Only for SP managers. */
  service_provider_organization_name: string | null = null;
  /** Admins don't have it. */
  use_dart?: boolean = undefined;
  /** Admins don't have it. */
  use_first_alerts?: boolean = undefined;
  /** Admins don't have it. */
  use_community?: boolean = undefined;
}

export type Login = {
  email: string;
  password: string;
};

export class CurrentUser {
  role: Role | null = null;
  user_id: string | null = null;
  exp: number | null = null;
  region: string | null = null;
  org_code: Org;
  first_alerts_community_id: string | null = null;
  service_provider_organization_id: string | null = null;
}

export type AuthSliceType = {
  authResult: AuthResult;
  loginApiStatus: APIStatus;
  currentUser: CurrentUser;
};

export const initialState: AuthSliceType = {
  authResult: new AuthResult(),
  loginApiStatus: APIStatus.IDLE,
  currentUser: new CurrentUser(),
};

export const login = createAsyncThunk<AuthResult, Login, AsyncThunkConfig>('auth/login', async (login, thunkAPI) => {
  try {
    const response = (await axios.post('v0_login', login)) as AuthResult;
    return response ?? new AuthResult();
  } catch (e) {
    logError(e, ['authSlice', 'login']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const logout = createAsyncThunk<undefined, undefined, AsyncThunkConfig>('auth/logout', async (_, thunkAPI) => {
  try {
    resetRaygunUser();
    const rememberedEmail = await getRememberedEmail();
    localStorage.clear();
    if (rememberedEmail) {
      await saveRememberedEmail(rememberedEmail);
    }
    thunkAPI.dispatch({ type: 'LOG_OUT' });
  } catch (e) {
    logError(e, ['authSlice', 'logout']);
    return thunkAPI.rejectWithValue(e);
  }
});

export const refreshToken = createAsyncThunk<AuthResult, undefined, AsyncThunkConfig>(
  'auth/refreshToken',
  async (_, thunkAPI) => {
    try {
      const res = await axios.post('v0_token_refresh');
      const response = res as unknown as AuthResult;
      if (!response?.token) {
        notification.error({
          message: 'Token expired',
          description: 'Please sign in again.',
        });
        thunkAPI.dispatch(logout());
      }
      return response;
    } catch (e) {
      logError(e, ['authSlice', 'refreshToken']);
      thunkAPI.dispatch(logout());
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    restoreAuthState: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, _action) => {
        state.loginApiStatus = APIStatus.PENDING;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload && action.payload.token) {
          state.authResult = action.payload;
          state.loginApiStatus = APIStatus.FULFILLED;
          state.currentUser = jwtDecode<JwtPayload & CurrentUser>(action.payload.token);
          setRaygunUser({
            user_id: state.currentUser.user_id,
            role: state.currentUser.role,
          });
        } else {
          state.authResult = new AuthResult();
          state.loginApiStatus = APIStatus.ERROR;
        }
      })
      .addCase(login.rejected, (state, _action) => {
        state.loginApiStatus = APIStatus.ERROR;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        if (action.payload && action.payload.token) {
          state.authResult = action.payload;
          state.currentUser = jwtDecode<JwtPayload & CurrentUser>(action.payload.token);
          setRaygunUser({
            user_id: state.currentUser.user_id,
            role: state.currentUser.role,
          });
        } else {
          state.authResult = new AuthResult();
        }
      });
  },
});

export const { restoreAuthState } = authSlice.actions;
